import React, { FC } from 'react';
import {
    Dialog,
    DialogContent,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger
} from '@components/dialog';
import { Button } from '@components/button';
import { PlusIcon } from 'lucide-react';
import { useForm } from 'react-hook-form';
import {
    Form,
    FormControl,
    FormDescription,
    FormField,
    FormItem,
    FormLabel,
    FormMessage
} from '@components/form';
import { Input } from '@components/input';
import { useApiMutation } from '@hooks/api/useApiMutation';
import { toast } from '@components/use-toast';
import Upload from '../upload';
import { apiCreateSkin, apiGetCharacters, apiGetSkins, TCreateSkin } from '@api/skins';
import { useApiQuery } from '@hooks/api/useApiQuery';
import { Select, SelectContent, SelectGroup, SelectTrigger, SelectValue } from '@components/select';
import { SelectItem } from '@radix-ui/react-select';
import { Switch } from '@components/switch';

interface IProps {
    onSuccess: () => void;
}

const CreateSkinModal: FC<IProps> = ({ onSuccess }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const form = useForm<TCreateSkin>({
        mode: 'onChange',
        defaultValues: {
            title: {
                uk: '',
                ru: '',
                en: ''
            },
            skin: {
                file: null,
                filename: '',
                mimetype: ''
            },
            logo: {
                file: null,
                filename: '',
                mimetype: ''
            },
            character: '',
            hidden: true
        }
    });

    const { handleRequest } = useApiMutation({
        method: apiCreateSkin,
        onSuccess: () => {
            toast({
                variant: 'default',
                description: `Skin has been successfully created`
            });
            setIsOpen(false);
            onSuccess();
        },
        onError: () => {
            toast({
                variant: 'destructive',
                description: `Something went wrong`
            });
        }
    });

    const { data: characters } = useApiQuery({
        method: apiGetCharacters,
        isInitialRequest: true
    });

    const onSubmit = () => {
        const values = form.getValues();

        console.log({ values });

        handleRequest(values);
    };

    return (
        <Dialog open={isOpen} onOpenChange={() => setIsOpen((state) => !state)}>
            <DialogTrigger asChild>
                <Button className={`gap-3 w-64 mx-auto`} variant="outline">
                    <PlusIcon /> Create New Skin
                </Button>
            </DialogTrigger>

            {isOpen && (
                <DialogContent
                    className="sm:max-w-[700px] min-w-[80vw] min-h-[80vh]  max-h-[80vh] overflow-y-auto flex flex-col"
                    onInteractOutside={(e) => e.preventDefault()}
                >
                    <DialogHeader>
                        <DialogTitle>Add new skin</DialogTitle>
                    </DialogHeader>
                    <Form {...form}>
                        <div className="flex flex-col gap-3">
                            <div className="flex gap-5">
                                <FormField
                                    name="skin"
                                    render={({ field, fieldState: { error } }) => (
                                        <FormItem className="w-full">
                                            <FormLabel>Skin</FormLabel>
                                            <FormControl>
                                                <Upload {...field} />
                                            </FormControl>

                                            {error?.message && (
                                                <FormDescription>Invalid field</FormDescription>
                                            )}
                                        </FormItem>
                                    )}
                                />

                                <FormField
                                    name="logo"
                                    render={({ field, fieldState: { error } }) => (
                                        <FormItem className="w-full">
                                            <FormLabel>Logo</FormLabel>
                                            <FormControl>
                                                <Upload {...field} />
                                            </FormControl>

                                            {error?.message && (
                                                <FormDescription>Invalid field</FormDescription>
                                            )}
                                        </FormItem>
                                    )}
                                />
                            </div>
                            <div className="flex gap-2">
                                <div className="w-full">
                                    <FormField
                                        name="title.uk"
                                        render={({ field, fieldState: { error } }) => (
                                            <FormItem className="w-full">
                                                <FormLabel>Name UA</FormLabel>
                                                <FormControl>
                                                    <Input placeholder="Name" {...field} />
                                                </FormControl>
                                                {error?.message && (
                                                    <FormDescription>Invalid field</FormDescription>
                                                )}
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Required field'
                                            }
                                        }}
                                    />
                                </div>
                                <div className="w-full">
                                    <FormField
                                        name="title.ru"
                                        render={({ field, fieldState: { error } }) => (
                                            <FormItem className="w-full">
                                                <FormLabel>Name RU</FormLabel>
                                                <FormControl>
                                                    <Input placeholder="Name" {...field} />
                                                </FormControl>
                                                {error?.message && (
                                                    <FormDescription>Invalid field</FormDescription>
                                                )}
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Required field'
                                            }
                                        }}
                                    />
                                </div>
                                <div className="w-full">
                                    <FormField
                                        name="title.en"
                                        render={({ field, fieldState: { error } }) => (
                                            <FormItem className="w-full">
                                                <FormLabel>Name EN</FormLabel>
                                                <FormControl>
                                                    <Input placeholder="Name" {...field} />
                                                </FormControl>
                                                {error?.message && (
                                                    <FormDescription>Invalid field</FormDescription>
                                                )}
                                                <FormMessage />
                                            </FormItem>
                                        )}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Required field'
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                            <FormField
                                name="hidden"
                                render={({ field }) => (
                                    <FormItem className="w-full flex items-center gap-2">
                                        <FormLabel>Is hidden</FormLabel>
                                        <FormControl>
                                            <Switch
                                                defaultChecked={true}
                                                checked={field.value}
                                                onCheckedChange={field.onChange}
                                            />
                                        </FormControl>
                                        <FormMessage />
                                    </FormItem>
                                )}
                            />

                            <FormField
                                name="character"
                                render={({ field, fieldState: { error } }) => (
                                    <FormItem className="w-full">
                                        <FormLabel>Character</FormLabel>
                                        <FormControl>
                                            <Select
                                                value={field.value}
                                                onValueChange={field.onChange}
                                            >
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Character" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectGroup>
                                                        {characters?.map((character) => (
                                                            <SelectItem
                                                                className="cursor-pointer"
                                                                key={character.id}
                                                                value={character.id}
                                                            >
                                                                {character.name}
                                                            </SelectItem>
                                                        ))}
                                                    </SelectGroup>
                                                </SelectContent>
                                            </Select>
                                        </FormControl>
                                        {error?.message && (
                                            <FormDescription>Invalid field</FormDescription>
                                        )}
                                        <FormMessage />
                                    </FormItem>
                                )}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Required field'
                                    }
                                }}
                            />
                        </div>
                    </Form>
                    <DialogFooter style={{ marginTop: 'auto' }}>
                        <Button onClick={onSubmit}>Save</Button>
                    </DialogFooter>
                </DialogContent>
            )}
        </Dialog>
    );
};

export default CreateSkinModal;
