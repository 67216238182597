import { useApiQuery } from '@hooks/api/useApiQuery';
import { apiGetSkins } from '@api/skins';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '@components/card';
import CreateSkinModal from '../components/modals/create_skin';

const Skins = () => {
    const { data: skins, handleRequest: refetchSkins } = useApiQuery({
        method: apiGetSkins,
        isInitialRequest: true
    });

    return (
        <div className="flex flex-col gap-8">
            <h2 className="font-bold text-2xl">Skins Management</h2>

            <CreateSkinModal onSuccess={() => refetchSkins()} />

            {skins && (
                <div className="flex gap-4 flex-wrap">
                    {skins.map((item) => (
                        <Card
                            key={item._id}
                            className="w-[calc(50%-8px)] md:w-[calc(23%-20px)] md:min-w-[200px]  flex flex-col justify-between"
                        >
                            <CardHeader className="flex justify-center w-full">
                                <CardTitle className="flex justify-center items-center w-full gap-5">
                                    {item?.title?.uk}

                                    {item.logoUrl && (
                                        <img
                                            src={`${process.env.REACT_APP_IMAGE_URL}${item.logoUrl}`}
                                            alt={item?.title?.uk}
                                            className="w-10 h-10 object-cover"
                                        />
                                    )}
                                </CardTitle>
                            </CardHeader>
                            <CardContent className="flex justify-center items-center">
                                {item.skinUrl && (
                                    <img
                                        src={`${process.env.REACT_APP_IMAGE_URL}${item.skinUrl}`}
                                        alt={item?.title?.uk}
                                        className="w-25 h-50 object-cover"
                                    />
                                )}
                            </CardContent>
                            <CardFooter className="flex gap-2">
                                {/* <UpdateTaskModal data={item} onSuccess={refetchTasks} />
                                <DeleteCardModal
                                    title="Delete current task"
                                    content="Are you sure you want to delete that task?"
                                    onSuccess={() => {
                                        handleDeleteTask(item.id);
                                        refetchTasks();
                                    }}
                                /> */}
                            </CardFooter>
                        </Card>
                    ))}
                </div>
            )}
        </div>
    );
};

export default Skins;
