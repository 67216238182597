import axios, { AxiosResponse } from 'axios';
import { IImage } from 'src/typescript/requests';
import { TLocalization } from '@types';

export type TCreateSkin = {
    character: string;
    title: TLocalization;
    logo: IImage;
    skin: IImage;
    hidden: boolean;
};

export type TSkin = {
    _id: string;
    character: string;
    title: TLocalization;
    logoUrl: string;
    skinUrl: string;
    hidden: boolean;
};
export type TCharacter = {
    id: string;
    characterId: number;
    name: string;
};

export type TUpdate = TCreateSkin & { id: string };

// Fetch all skins
export const apiGetSkins = (): Promise<AxiosResponse<TSkin[]>> => {
    return axios.get('/skins');
};

// Fetch all characters
export const apiGetCharacters = (): Promise<AxiosResponse<TCharacter[]>> => {
    return axios.get('/skins/characters');
};

// Fetch a skin by ID
export const apiGetSkinById = (id: string): Promise<AxiosResponse<TSkin>> => {
    return axios.get(`/skins/id/${id}`);
};

// Create a new skin
export const apiCreateSkin = (data: TCreateSkin): Promise<AxiosResponse<TSkin>> => {
    return axios.post('/skins', data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
};

// Update an existing skin
export const apiUpdateSkin = ({ id, ...data }: TUpdate): Promise<AxiosResponse<TSkin>> => {
    return axios.put(`/skins/${id}`, data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    });
};

// Delete a skin
export const apiDeleteSkin = (id: string): Promise<AxiosResponse<boolean>> => {
    return axios.delete(`/skins/${id}`);
};
